<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <SearchForm class="ml-auto" model="donor" :use-search="true" @searchQuery="searchQuery" />
  </nav>
</template>

<script>
import serach from '../../mixins/hasSearch';
import SearchForm from '../forms/SearchForm';

export default {
  name: 'DonorsNavigation',
  mixins: [serach],
  components: { SearchForm },
};
</script>
