<template>
  <tr>
    <td>
      <router-link
        :to="{ name: 'donor', params: { id: donor.donor_id } }"
        v-if="can('view', 'donors')"
      >
        {{ donor.donor_id }}
      </router-link>
      <template v-else>
        {{ donor.donor_id }}
      </template>
    </td>
    <td>
      <router-link
        :to="{ name: 'donor', params: { id: donor.donor_id } }"
        v-if="can('view', 'donors')"
      >
        {{ fullName }}
      </router-link>
      <template v-else>
        {{ fullName }}
      </template>
    </td>
    <td v-if="can('view', 'donors')">{{ donor.email }}</td>
    <td>{{ donor.phone }}</td>
    <td>{{ donor.city }}</td>
    <td>{{ donor.state }}</td>
    <td>{{ donor.zip }}</td>
  </tr>
</template>

<script>
export default {
  name: 'DonorsListRow',
  props: {
    donor: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    donations() {
      const donations = this.$store.getters['donations/getAll'];
      console.log('components DonorsListRow computed donations', donations);
      return donations.filter((donation) => donation.donor_id === this.donor.donor_id);
    },
    fullName() {
      if (this.can('view', 'donors')) {
        if (this.donor.display_as) {
          return this.donor.display_as;
        }

        return [this.donor.first, this.donor.last].join(' ');
      }

      return this.donor.first;
    },
    remoteUser() {
      return this.donor.remote_user === 'Y' ? 'Yes' : 'No';
    },
  },
  methods: {},
};
</script>
